import "@arenza/typefaces-museo-sans";
import PropTypes from "prop-types";
import React from "react";

import "../assets/main.scss";

import LayoutAdaptiveWrapper from "./LayoutAdaptiveWrapper";
import LayoutApplicationContext from "./LayoutApplicationContext";
import LayoutNavigation from "./LayoutNavigation";

Layout.propTypes = {
  children: PropTypes.node,
};
export default function Layout({ children }) {
  return (
    <LayoutAdaptiveWrapper>
      <LayoutApplicationContext>
        <LayoutNavigation>{children}</LayoutNavigation>
      </LayoutApplicationContext>
    </LayoutAdaptiveWrapper>
  );
}
