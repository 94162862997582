import PropTypes from "prop-types";
import React from "react";

import { Box } from "@material-ui/core";

LayoutAdaptiveWrapper.propTypes = {
  children: PropTypes.node,
};

export default function LayoutAdaptiveWrapper({ children }) {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        height: "100%",
      }}
    >
      <Box
        sx={{
          zIndex: 1,
          margin: "0 auto",
          backgroundColor: "white",
          maxWidth: { md: 600, lg: 1440 },
          height: "100%",
          minHeight: "inherit",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
