import PropTypes from "prop-types";
import React, { useState } from "react";

import {
  IconButton,
  Link,
  List,
  ListItem,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import LoginIcon from "@material-ui/icons/Login";
import {
  ButtonLink,
  ElevationAppBar,
  LogoArenzaRenew,
  LogoArenzaRenewText,
} from "@product-site-frontend/shared";
import { Link as GatsbyLink } from "gatsby";

import { NAV_LINKS } from "../constants/nav-links";
import IconBurger from "./IconBurger";

AppBar.propTypes = {
  drawerOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func.isRequired,
};

export default function AppBar({ drawerOpen, toggleDrawer }) {
  const theme = useTheme();

  const matchesMd = useMediaQuery(theme.breakpoints.only("md"));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    if (matchesMd) {
      setAnchorEl(event.currentTarget);
    } else {
      toggleDrawer();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "nav-popover" : undefined;

  return (
    <ElevationAppBar hideLogo={true}>
      <Box sx={{ display: { xs: "flex", lg: "none" } }}>
        <IconButton edge="end" onClick={handleClick}>
          <IconBurger open={drawerOpen || open} />
        </IconButton>
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 48,
            horizontal: 54,
          }}
          id={id}
          onClose={handleClose}
          open={open}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box
            sx={{
              backgroundImage: theme =>
                `linear-gradient(332deg, ${theme.palette.primary.light} 10%, ${theme.palette.primary.main} 60%)`,
              color: "white",
              py: 3,
              width: 600,
            }}
          >
            <List>
              {NAV_LINKS.map(({ link, text }) => (
                <ListItem button component="a" href={link} key={link}>
                  <Typography
                    sx={{ fontSize: 20, fontWeight: 700, px: 3, py: 2 }}
                    variant="subtitle2"
                  >
                    {text}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Box>
        </Popover>
        <Box sx={{ marginRight: '16px' }} />
      </Box>

      <Box flexShrink={0} height={{ xs: 24, lg: 34 }} sx={{ width: {xs: "calc(100% - 64px - 38px)", lg: "auto" }, display: "flex", justifyContent: "center", }}>
        <Box component={GatsbyLink} height="inherit" to="/">
          <LogoArenzaRenew />
        </Box>
        <Box component={GatsbyLink} height="inherit" sx={{ display: { xs: "none", sm: "block", lg: "block" }, height: "14px", marginTop: {sm: "2px", lg: "7px"}, marginLeft: "7px" }} to="/">
          <LogoArenzaRenewText />
        </Box>
      </Box>

      <Box ml="auto" />
      <Box component="nav" sx={{ display: { xs: "none", lg: "flex" } }}>
        {NAV_LINKS.map(({ link, text }) => (
          <Link
            color="#6E7884"
            href={link}
            key={link}
            sx={{ mr: 3, fontWeight: 500 }}
            underline="none"
            variant="body1"
          >
            {text}
          </Link>
        ))}
      </Box>
      <ButtonLink
        sx={{
          color: '#6E7884',
          textTransform: 'none',
          fontWeight: '500',
          padding: { xs: "3px 9px", lg: "5px 15px" },
          fontSize: { xs: "0.8125rem", lg: "16px" },
          border: {
            xs: "none", lg: '1px solid #E7E8EA'
          },
          borderRadius: '8px',

          '&:hover':{
            color: '#FF780F',

            '& svg path': {
              fill: '#FF780F',
            },
          },
        }}
        to={process.env.GATSBY_ACCOUNT_URL}
        variant="outlined"
      >
        <Typography sx={{display: { xs: "none", lg: "flex" }}} variant="inherit">Войти</Typography>
        <LoginIcon sx={{ marginLeft: '10px', color: '#B6BBC2' }} />
      </ButtonLink>
    </ElevationAppBar>
  );
}
