// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-category-[id]-jsx": () => import("./../../../src/pages/category/[id].jsx" /* webpackChunkName: "component---src-pages-category-[id]-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-product-[id]-jsx": () => import("./../../../src/pages/product/[id].jsx" /* webpackChunkName: "component---src-pages-product-[id]-jsx" */)
}

