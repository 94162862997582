import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

import { green } from "../theme/colors";

export default function LogoArenzaRenew(props) {
  return (
    <SvgIcon
      htmlColor={green}
      sx={{
        height: "100%",
        width: "auto",
      }}
      viewBox="0 0 29 34"
      {...props}
    >
      <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
        <g fill="#FF780F" id="RenewLogo" transform="translate(0.000000, 0.775400)">
          <path d="M29.4656,5.2858 L19.9822,0 L10.6543,5.2858 L19.9822,11.038 L19.9822,21.7651 L29.4656,16.1683 L29.4656,5.2858 Z M0,12.9517 L0.149526,23.8077 L9.38385,29.2553 L9.7161,18.3015 L19.0132,12.9503 L9.43183,7.5231 L0,12.9517 Z M20.0752,34.4484 L29.3826,28.8585 L29.4459,18.1373 L19.8116,23.36 L10.5102,18.0163 L10.639,29.0273 L20.0752,34.4484 Z" id="Shape"></path>
        </g>
      </g>
    </SvgIcon>
  );
}
