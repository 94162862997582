import PropTypes from "prop-types";
import React from "react";

import { Button } from "@material-ui/core";
import { Link } from "gatsby";

ButtonLink.propTypes = {
  to: PropTypes.string.isRequired,
};

export default function ButtonLink({ to, ...props }) {
  const internal = /^\/(?!\/)/.test(to);
  if (internal) {
    return <Button component={Link} to={to} {...props} />;
  }
  return <Button component="a" href={to} {...props} />;
}
